.border-btn {
    border-bottom: 1px solid #f3f3f3;
}

.nav-item>a {
    text-align: center;
}

.rounded {
    padding-bottom: 60px !important;
    min-height: 100vh;
}

body {
    padding-bottom: 60px !important;
    min-height: 100vh;
}

.custom-lead {
    font-size: 1rem;

    span {
        font-size: 0.8rem;
        font-weight: 300;
    }

    .glyph-icon {
        margin: 0px 6px;
        display: inline-block;

        &::before {
            font-size: 0.7rem;
            font-weight: bold;
        }
    }

    margin-bottom: 0px;
}

// navbar overwrtite css fixed to sticky
nav.navbar {
    position: sticky !important;
}

.dspl-none {
    display: none;
}

.for-table-size {
    max-width: 1318px;
    margin: auto;
}

.hidden {
    visibility: hidden;
}

#update-btn {
    min-height: 42.59px;
}

.dashboard-filled-line-chart-sales {
    @media only screen and (max-width: 576px) {
        .card-body:last-child {
            padding: 1.8px;
        }

        .chart-container-sales {
            height: 224px;
        }
    }

    @media only screen and (min-width: 576px) {
        .chart-container-sales {
            height: 284px;
        }
    }
}

// top-panel mobiscrolls
.overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -25%;
    z-index: 4002;
    // background-image: linear-gradient(120deg, #eaee44, #33d0ff);
    // opacity: .7;
}

.tt-select {
    @media only screen and (max-width: 992px) {
        margin-bottom: 12px;
    }
}

.mobiscroll-layout {
    height: 42.59px;

    flex-grow: 1;
    margin-left: 15px;
    margin-right: 15px;

    @media only screen and (max-width: 420px) {
        // height: 40px;
        // margin-top: 12px;
        margin-bottom: 12px;
        // margin-right: 0px;
    }

    &>div {
        // width: min-content;
        height: 100%;
        // background-color: rgb(255, 255, 255);
        display: grid;
        align-content: center;
        align-items: center;
        grid-auto-flow: column;
        // font-size: 1rem;
        padding: 4px 5px;
        overflow: hidden;
        gap: 2px;
        border-width: 1px;
        border-style: solid;
        // border-color: hsl(0, 0%, 80%);
        border-image: initial;

        // border-radius: 12px;
        // color: hsl(0, 0%, 80%);
        input {
            justify-self: center;
            font-size: 0.8rem;
            background: none;
            width: 6em;
            margin: 0px;
            padding: 0px;
            outline: 0px;
            border-width: 0px;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0);
            border-image: initial;
        }

        .mbi-label {
            @media only screen and (max-width: 1144px) {
                visibility: hidden;
            }

            @media only screen and (max-width: 992px) {
                visibility: visible;
            }

            display: flex;
            justify-content: flex-end;
            color: hsl(0, 0%, 50%);
        }
    }
}

//все заказы кнопка
.progress-banner-order {
    padding: 8px 0;
    transition: 0.5s;
    background-size: 200% auto;
    cursor: pointer;

    .lead {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;

        @media only screen and (max-width: 420px) {
            font-size: 1rem;
            margin-bottom: 0.2rem;
        }
    }

    i {
        font-size: 1.8rem;
        margin-bottom: 1rem;

        @media only screen and (max-width: 420px) {
            font-size: 1.2rem;
        }
    }

    &:hover {
        background-position: right top;
    }
}

.unit-card {
    .click-able:hover {
        cursor: pointer;
    }

    .unit-card-row {
        &:hover {
            cursor: pointer;
        }

        @media only screen and (max-width: 768px) {
            & {
                padding: 1.75rem;
                font-size: 0.9rem !important;

                .col-3,
                .col-6 {
                    padding-left: 0px;
                }
            }
        }

        .col-6 {
            display: flex;
            align-items: center;

            i {
                text-decoration: none !important;
                padding-right: 1rem;
            }
        }
    }

    @media only screen and (max-width: 420px) {

        .borderBtn .col-3,
        .borderBtn .col-6,
        .row .col-3,
        .row .col-6 {
            padding-left: 0px;
            padding-right: 0px;
            margin: auto;
        }

        .dropdown button.btn-xs {
            max-width: 134px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .qty-unit-btn {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

// icon-card
.labels-card-body {
    padding-top: 22px !important;
    padding-bottom: 22px !important;

    .initial-card-text {
        line-height: 48px;
    }

    .card-text:not(.initial-card-text) {
        height: auto;
    }
}

@media only screen and (min-width: 1144px) {

    // main overwrite css top
    #app-container>.sidebar+main {
        margin-top: 45px !important;
    }
}

@media only screen and (max-width: 1144px) {

    // main overwrite css top
    #app-container>.sidebar+main {
        margin-top: 35px !important;
    }
}

.cstm-tooltip_kpi {
    span {
        margin: 0px 4px;
    }

    display: flex;
}

#app-container .mb-4.row-glide_cstm.row>div:nth-child(1)>div>div>div>div.glide__track {
    box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.04);
}

.mb-4.row-glide_cstm.row {
    .icon-cards-row {
        margin-top: 0 !important;
    }
}

// footer css on container
footer.page-footer {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-top: 1px solid #d7d7d7;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
}

// settings-user-dropdown
.cstm-user-right::before {
    font-size: 20px;
}

.user div[role="menu"] button[role="menuitem"]>span {
    margin-right: 6px;
}

// mozzila selection
button::-moz-focus-inner {
    border: 0 !important;
}

// statistic page normalize ofset footer
// @media only screen and (min-width: 1200px) {
//     .col-lg-12.col-xl-6 > div.mb-5.row:last-of-type,
//     #app-container > main > div > div > div > div:nth-child(3) > div > div > div:nth-child(3) {
//         margin-bottom: 0px !important;
//     }
// }
// @media only screen and (max-width: 1200px) {
//     #app-container > main > div > div > div > div:nth-child(3) > div {
//         margin-bottom: 0px !important;
//     }
// }

// navigation items text align left
.sub-menu>.scroll>.scrollbar-container>ul>li>a {
    text-align: left;
}

// fix chart title
#pivotgrid {
    margin-top: 1.1rem;
}

.kpi__top-panel-title {
    align-content: center;
    font-size: 1.1rem;
}

.kpi__top-panel-range {
    margin: 0;
    width: 100% !important;
}


@media only screen and (max-width: 767px) {
    #sets-fields {
        height: 27.5px !important;
    }

    .kpi-title-row {
        padding-left: 1rem;
        padding-right: 1rem;

        .mobiscroll-layout {
            height: 27.5px !important;
        }

        &>div:last-child {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .type-of-chart-btn {
            width: 100%;
            height: 100%;
            // margin-right: 1rem;

            button {
                padding: 0;
                border-color: #fff;
                margin-right: 0.2rem;

                &:hover {
                    cursor: pointer;
                }

                svg {
                    height: 26px !important;
                    width: 36px !important;
                    vertical-align: middle;
                }
            }
        }

        .sizes-btn-cstm>button {
            padding: 4px 12px !important;
        }
    }
}

// buttons-chart types
.type-of-chart-btn {
    .active {
        border-color: #922c88 !important;
        border-top-style: outset;
        border-right-style: outset;
        border-bottom-style: outset;
        border-left-style: outset;
    }

    // margin-right: 2rem;
    width: 100%;
    height: 100%;

    &>button {
        background-color: white;
        padding: 0;
        border-width: 1px;
        border-color: #fff;
        margin-right: 0.4rem;
        border-top-style: none;
        border-right-style: none;
        border-bottom-style: none;
        border-left-style: none;

        &:hover {
            cursor: pointer;
        }

        svg {
            padding: 2px 0px;
            height: 34.5px;
            width: 38.5px;
            vertical-align: middle;
        }
    }
}

// fields_modal
#sets-fields {
    margin-right: 12px;
    vertical-align: top;

    &:hover {
        border-color: #922c88 !important;
        border-top-style: outset;
        border-right-style: outset;
        border-bottom-style: outset;
        border-left-style: outset;
        cursor: pointer;
    }

    border-width: 1px;
    height: 36.5px;
    background-color: white;
    border-color: #fff;
    border-top-style: unset;
    border-right-style: unset;
    border-bottom-style: unset;
    border-left-style: unset;
}

.kpi-title-row {
    .mobiscroll-layout {
        flex-grow: 0.2;

        .mbi-label {
            color: #303030 !important;
            visibility: visible !important;
        }

        height: 36.5px;
    }
}

.select-add-form {
    div {
        width: 70%;
    }

    div>input {
        width: 100%;
    }

    width: 100%;
    display: flex;
    justify-content: space-around;
}

#add-fields__btn {
    height: 45.2px;
}

#remove-fields__btn {
    vertical-align: middle;
    font-size: 1.5rem;
    margin-left: 1rem;

    &:hover {
        cursor: pointer;
    }
}

#fields-select {
    margin-right: 12px;
    // button {
    //     background-color: white;
    //     padding: 0;
    //     border-width: 1px;
    //     border-color: #fff;
    //     margin-right: 0.4rem;
    //     border-top-style: none;
    //     border-right-style: none;
    //     border-bottom-style: none;
    //     border-left-style: none;
    //     &:hover {
    //         cursor: pointer;
    //     }
    // height: 36.5px;
    // margin-bottom: 0.5rem !important;
    // padding: 0.5rem 1rem;
    // margin-right: 12px;
}

#sets-click {
    &:hover {
        color: #922c88 !important;
        cursor: pointer;
    }
}

// selecte item
.list-item {
    padding: 0;
}

// labels area
.row-labels {
    display: flex;

    @media only screen and (max-width: 576px) {
        flex-direction: column;
    }

    .icon-row-item>.card>div {
        display: flex;
        flex-direction: column;
        cursor: default;
    }
}

@media screen and (max-width: 465px) {
    .kpi-ranges {
        display: flex !important;
        flex-direction: column;
        position: relative;

        .kpi-ranges-labelA {
            position: absolute;
            left: calc(50vw - 155px);
            top: 15px;
        }

        .kpi-ranges-labelB {
            position: absolute;
            left: calc(50vw - 155px);
            top: 72px;
        }
    }

}

@media screen and (max-width: 270px) {

    .kpi-ranges-labelA,
    .kpi-ranges-labelB {
        display: none;
        opacity: 0;
    }
}

.col-sm-4 {
    flex-shrink: 1;
}
